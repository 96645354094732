.App {
  text-align: center;
  width: 100vw;
  max-width: 100vw;
  height: fit-content;
  min-height: 100vh;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background-color: var(--main-white); */
  /* color: var(--main-black); */
}