main {
    height: 100% !important;
}

.winner-box {
    color: green;
}

.leaderboard, 
.winner-box,
.points-table {    
    width: 90% !important;
    border: 1px solid var(--main-grey1);
    padding: 1rem;
    border-radius: 0.5rem;
}

.toggle-all-players {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
     & input {
        width: auto !important;
     }
}

.add-points {
    width: 90% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

main li {
    width: 80%;    
    display: flex;
    justify-content: space-between;
}

.winner {
    color: green;
}

.host-container-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.host-control-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* gap: 5% !important; */
    align-items: center;
    row-gap: 1rem;
}

.host-control {
    width: 45% !important;
    border: 1px solid var(--main-grey1);
    border-radius: 0.5rem;
    padding: 0.5rem;
}

.player-container-div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.end-game-check {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border: 1px solid red;
    padding: 1rem;
    border-radius: 1rem;

}

.end-game-check div {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.end-game-check button {
    width: 45%;
}

.end-game-check button:first-of-type {
    background-color: red;
    color: white;
}

.add-player-container {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .player-info {
        width: 100% !important;
        border: 1px solid grey;
        border-radius: 0.5rem;
        padding: 0.5rem;
    }
}