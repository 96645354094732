.points-table {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0;
    overflow-x: scroll;

    & .table-container {
        min-width: 100%;
        width: fit-content;
        display: flex;
        justify-content: space-between;
    }

    & .player-points-column {
        width: 50%;
        min-width: 130px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        
        & .player-points-header {
            width: 100%;
            height: 3rem;
        }
        & h6 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 3rem;
            word-break: normal;
            line-height: 1.2rem;
            overflow-y: scroll;
        }

        & .player-points-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            
            & p {
                border-top: 1px solid grey;
            }
        }

        & .player-points-footer {
            width: 100%;
            border-top: 1px solid grey;
            display: flex;
            justify-content: center;    
            /* margin-top: 0.5rem; */
        }

        & .player-points-footer p {
            height: 2rem;
            width: 2rem;
            border-radius: 1rem;
            margin: 0.5rem;
        }
    }

    & .player-points-column:not(.player-points-column:last-of-type) {
        border-right: 1px solid grey;
    }
}

.player-points-footer,
.player-points-container {
    display: flex;
}
