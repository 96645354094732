.end-game {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.end-game li {
    width: 80%;    
    display: flex;
    justify-content: space-between;
}

.new-game-check {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    border: 1px solid red;
    padding: 1rem;
    border-radius: 1rem;

    & button {
        width: 100%;
        padding: 0.3rem 0;
    }

    & button:last-of-type {
        background-color: red;
        color: white;
    }

    & div {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;


    }
}