.add-points {
    width: 100%;
    display: flex;
    justify-content: center;
    border-bottom: 0 !important;

    & .host-control {
        width: 100% !important;
        border: 1px solid var(--main-grey1);
        border-radius: 0.5rem;
        padding: 0.5rem;
        gap: 0.3rem;

        & form {
            gap: 0.3rem;
        }
    }

}

.add-points .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;

    
}
.add-points .buttons input[type='submit'], 
.buttons button {
    width: 40%;
    border-radius: 0.5rem;
    padding: 0.3rem 0;
}

.buttons button {
    display: flex;
    justify-content: center;
}