.leaderboard ul {
    gap: 0rem !important;
}

.leaderboard h4:first-of-type {
    max-width: 80%;
    overflow-x: hidden;
}

.leaderboard li {
    width: 100%;
    & div {
        width: 80%;
        display: flex;
        gap: 0.1rem;
        align-items: center;

        & svg {
            width: 20%;
            display: flex;
            justify-content: flex-start;

        }

    }
}