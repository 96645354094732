body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  max-width: 100vw;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 1.2rem;
}

.standard-div, 
form {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

li {
  list-style: none;
}

:root {
  --main-white: rgb(238, 238, 238);
  --main-black: rgb(19, 19, 19);
  --main-grey1: rgb(121, 121, 121);
  --main-grey2: rgb(53, 53, 53);
}

button,
input[type='submit'] {
  border: 0;
  padding: 0.3rem 2rem;
  border-radius: 0.5rem;
}

input {
  border: 0;
  padding: 0.3rem 0rem;
  width: 100%;
}

svg {
  border: 0 !important;
}

hr {
  width: 100%;
  margin: 1rem 0;
}